import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Button, Container, Grid, Link, Typography} from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import ConsentDetailsHeader from "../components/ConsentDetailsHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import SwitchWithLabel from "../components/SwitchWithLabel";
import Consent from "../models/Consent";
import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";
import ModalPopUpData from "../models/ModalPopUpData";
import DataCluster from "../openbankingplatform/models/DataCluster";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import modalPopUpContents from "../popups/ModalPopUpDataHelper";
import Popup from "../popups/Popup";
import { sanitize } from "../helpers/HtmlSanitizerHelper";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";
import EditableRichText from "../admin/components/EditableRichText";
import PreConsent from "../models/PreConsent";
import Insight from "../models/Insight";

interface WithdrawConsentProps {
    basePath: string;
    api: OpenBankingPlatformAPI,
    dashboardPath: string;
    onDeleteOptionChange: (newOption: boolean) => void;
    currentDeleteOption: boolean | undefined;
    preconsentConfig?: PreConsent;
    consentConfig?: Consent;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    loadPrincipalConfiguration: (principal_id?: number) => void;
    insightConfig?: Insight;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface ConsentDetailsState {
    consentDetails?: ConsentListItemData;
    dataClusters: Array<DataCluster>;
    sharingStartAt?: string;
    sharingEndAt?: string;
    consentStatus: string;
    delete_my_data_after_used?: boolean;
    snapShotPreConsentData?: PreConsent;
    snapShotConsentData?: Consent;
    is_insight?: boolean;
}

const WithdrawConsent = (props: WithdrawConsentProps) => {
    const { id } = useParams<{ id: any }>();

    const [state, setState] = useState<ConsentDetailsState>({
        dataClusters: [],
        consentStatus: '',
        delete_my_data_after_used: false
    });

    const location = useLocation();
    useEffect(() => {
        const consentDetails = location.state as ConsentListItemData;
        setState((state) => ({
            ...state,
            consentDetails: consentDetails,
            sharingStartAt: consentDetails.sharing_start_at,
            sharingEndAt: consentDetails.sharing_end_at,
            consentStatus: consentDetails.status,
            delete_my_data_after_used: consentDetails.delete_my_data_after_used,
            snapShotPreConsentData: consentDetails.consent_snapshot?.preConsent,
            snapShotConsentData: consentDetails.consent_snapshot?.consent,
            is_insight: consentDetails.is_insight,
        }))
        setDeleteDataOption(consentDetails.delete_my_data_after_used);

    }, [location]);

    var newDeleteOption = false;
    if (props.currentDeleteOption) {
        newDeleteOption = props.currentDeleteOption;
    }

    const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);

    const modalPopupsData = modalPopUpContents(
        props.generalSettingsConfig,
        state.consentDetails?.consent_snapshot?.preConsent,
        state.consentDetails?.consent_snapshot?.consent,
        props.generalSettingsConfig?.principalName,
        state.consentDetails?.institution_name
    );

    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [popUpModalDatas, setPopUpModalDatas] = React.useState<ModalPopUpData>();

    const openPopup = (prop: ModalPopUpData) => {
        setOpen(true);
        setPopUpModalDatas(prop);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const handleSave = () => {
        navigate(props.dashboardPath + `/consent-details/${id}/withdraw-confirmation`, {
            state: state.consentDetails
        });
    };

    const deIdentifyPopup = () => {
        const identifypopupopupdatas = modalPopupsData[1];
        openPopup(identifypopupopupdatas);
    }

    const deletePopup = () => {
        const deletepopupopupdatas = modalPopupsData[2];
        openPopup(deletepopupopupdatas);
    }

    const popUpgeneralTexts = popUpModalDatas?.popUpGeneralText.map((item, index) =>
        <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(item.content) }}></p>
    );

    const popUpBoxTexts = popUpModalDatas?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockTexts = popUpModalDatas?.popupBlockContents.map((item, index) => {
        return (
            <div key={index}>
                <h6>{item.heading}</h6>
                <br />
                {
                    item.content?.map((word, index) => {
                        return (
                            <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(word.text) }}></p>
                        )
                    })
                }
                <br />
            </div>
        )
    });

    const handleDeleteDataOption = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        if (props.onDeleteOptionChange) {
            props.onDeleteOptionChange(currentOption);
            var consentDetails = state.consentDetails;
            if (consentDetails) {
                consentDetails.delete_my_data_after_used = currentOption;
            }
            setState((state) => ({
                ...state,
                consentDetails: consentDetails,
                delete_my_data_after_used: currentOption
            }))
        }
    }

    const handleGoBack = () => {
        navigate(props.dashboardPath + `/consent-details/${id}`);
    };

    const handleShowHistory = () => {
        navigate(props.dashboardPath + `/consent-history/${id}`, {
            state: state.consentDetails
        });
    }

    const snapShotConsentConfig = state.snapShotConsentData ? state.snapShotConsentData : props.consentConfig;

    // Reset to default configured principal on dashboard load
    useEffect(() => {
        (async () => {
            await props.loadPrincipalConfiguration(0);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={"page-wrapper withdraw-consent"}>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <Container maxWidth={"lg"}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath); }}>Dashboard</Link>
                        <Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath + `/consent-details/${id}`); }}>{sanitize(state.consentDetails?.institution_name)}</Link>
                        <span>Stop sharing</span>
                    </Breadcrumbs>

                    <ConsentDetailsHeader
                        basePath={props.basePath}
                        consentDetails={state.consentDetails}
                        consentStatus={state.consentStatus}
                        handleShowHistory={handleShowHistory}
                        api={props.api}
                    />

                    <Box mb={2}>
                        <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box mt={2}>
                                <h2>Stop sharing</h2>
                                <Typography component={'div'} color={"secondary"}><h4 className={"mt-20 font-weight-normal"}>When you stop sharing</h4></Typography>

                                {(snapShotConsentConfig?.includeDataDeidentificationStandardText) ?
                                    <>
                                        {(snapShotConsentConfig?.dataDeidentificationText) ?
                                            <p>{snapShotConsentConfig.dataDeidentificationText}</p> :
                                            <p>We will de-identify your data when we no longer need it to help you track your spending, and
                                                there Is no legal obligation to retain it.</p>
                                        }

                                        {(snapShotConsentConfig?.dataDeidentificationPopupLinkText && snapShotConsentConfig?.dataDeidentificationPopupLinkText !== '') ?
                                            <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                e.preventDefault();
                                                deIdentifyPopup();
                                            }}>{snapShotConsentConfig?.dataDeidentificationPopupLinkText}</a></p> :
                                            <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                e.preventDefault();
                                                deIdentifyPopup();
                                            }}>How and why we de-identify your data</a></p>
                                        }
                                    </> : ""
                                }
                            </Box>

                            {(state.is_insight ? props.insightConfig?.includeDataDeletionStandardText : snapShotConsentConfig?.includeDataDeletionStandardText) ? (
                                <div>
                                    <br />
                                    <SwitchWithLabel
                                        checked={deleteDataOption}
                                        onChange={value => {
                                            handleDeleteDataOption(value);
                                        }}
                                    >
                                        <p><strong> Delete my data instead </strong></p>
                                    </SwitchWithLabel>

                                    {(snapShotConsentConfig?.dataDeletionText && hasRichTextSpecifiedForField(snapShotConsentConfig?.dataDeletionText)) ?
                                        <p>
                                            <EditableRichText
                                                editMode={false}
                                                value={snapShotConsentConfig?.dataDeletionText}
                                            />
                                        </p> :
                                        <>
                                            {(state.consentDetails?.sharing_period !== '0') &&
                                            <p>You can also tell us to delete your data by going to Menu {'>'} Data Sharing or by writing to
                                                odssupport@illion.com.au.</p>
                                            }
                                            {state.sharingEndAt ? (
                                                <p>If you don't do this by <Moment format="DD MMM YYYY">
                                                    {state.sharingEndAt}
                                                </Moment>, your data will be de-identified.</p>
                                            ) : (
                                                <p>If you don’t do this, your data will be de-identified.</p>
                                            )}
                                        </>
                                    }

                                    {(snapShotConsentConfig?.dataDeletionPopupLinkText && snapShotConsentConfig?.dataDeletionPopupLinkText !== '') ?

                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            deletePopup()
                                        }}>{snapShotConsentConfig?.dataDeletionPopupLinkText}</a></p> :

                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            deletePopup()
                                        }}>See how we delete your data</a></p>
                                    }
                                </div>
                            ) : ("")}

                        </Grid>
                    </Grid>

                    <Box mb={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6}>
                                <ButtonBlock className={"text-align-right text-align-center-xs mt-0"}>
                                    <LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}`} variant={"outlined"} color={"secondary"}>Cancel</LinkButton>
                                    <Button onClick={handleSave} variant={"contained"} color={"secondary"}>Continue</Button>
                                </ButtonBlock>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                {/* Information popup */}
                <Popup
                    heading={popUpModalDatas?.popUpHeading}
                    open={open}
                    withCloseButton
                    onClose={closePopup}
                >
                    {popUpgeneralTexts}
                    {popUpBoxTexts && popUpBoxTexts.length > 0 &&
                        <Box my={3} p={3} className={"background-grey"}>{popUpBoxTexts}</Box>
                    }
                    {popUpBlockTexts}
                    <a href={props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer">View Consumer Data Right policy</a>
                </Popup>
            </main>
        </div>

        {!props.generalSettingsConfig?.hideFooter && <Footer generalSettingsConfig={props.generalSettingsConfig} />}

    </div>;
}

export default WithdrawConsent;