import { convertFromRaw, Editor, EditorState } from "draft-js";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { faArrowLeft, faCheckCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Grid, InputBase, Link, Paper, Typography, useTheme } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Consent from "../models/Consent";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import MultibankSelectionData from "../models/MultibankSelectionData";
import PreConsent from "../models/PreConsent";
import PreConsentData from "../models/PreConsentData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import Popup from "../popups/Popup";
import { sanitize } from "../helpers/HtmlSanitizerHelper";

interface DataHolderSelectionProps {
    onSelectionChange: (newSelection: DataHolder, loadedPreConsentData?: PreConsentData) => void;
    haveAuthorisationWith?: Map<string, boolean>;
    next: string;
    api: OpenBankingPlatformAPI;
    basePath: string;
    preConsentConfig?: PreConsent;
    consentConfig?: Consent;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
}


interface DataHolderSelectionState {
    filter?: string;
    newDataHolders?: Array<DataHolder>;
    sessionData: Array<MultibankSelectionData>;
    selectedDataHolder?: DataHolder;
    returnToDashboard: boolean;
    showNotice?: boolean;
    showAllBanks?: boolean;
}

const DataHolderSelection = (props: DataHolderSelectionProps) => {
    const [open, setOpen] = React.useState(false);

    const openPopup = () => {
        setOpen(true);
    };
    const closePopup = () => {
        setOpen(false);
    };


    const [state, setState] = useState<DataHolderSelectionState>({
        sessionData: [],
        returnToDashboard: false,
    });
    const navigate = useNavigate();

    const location = useLocation();
    useEffect(() => {
        // @ts-ignore
        if (location.state) {
            // @ts-ignore
            if (location.state.showNotice) {
                setState(state => ({
                    ...state,
                    showNotice: true
                }));
            }

            // @ts-ignore
            if (location.state.returnToDashboard) {
                // @ts-ignore
                const returnToDashboard: boolean = location.state.returnToDashboard;
                setState((state) => ({
                    ...state,
                    returnToDashboard: returnToDashboard,
                }));
            }
        }
    }, [location]);


    var sessionId = sessionStorage.getItem('sessionId');


    // Make sure to clear any selected data holder brand first in sessionStorage
    sessionStorage.removeItem("dataHolderBrand")

    //eslint-disable-next-line 
    const getDataHolders = useCallback(props.api.getDataHolders, []);
    //eslint-disable-next-line
    const getMultibankSessionDetails = useCallback(props.api.getMultibankSession, []);
    useEffect(() => {
        (async () => {
            try {
                const loadedDataHolders = await getDataHolders();
                if (sessionId !== null) {
                    const multibankSessionData = await getMultibankSessionDetails(sessionId);
                    setState((state) => ({
                        ...state,
                        sessionData: multibankSessionData,
                    }));
                }
                const newDataHoldersArray = loadedDataHolders.map((loadedDataHolder) => {
                    const newDataHolderSet: DataHolder = {
                        data_holder_id: loadedDataHolder.id,
                        id: loadedDataHolder.identifier,
                        title: loadedDataHolder.name,
                        logo: loadedDataHolder.logo_uri,
                        is_cdr: loadedDataHolder.is_cdr,
                        is_top_bank: loadedDataHolder.is_top_bank,
                    }
                    return newDataHolderSet;
                });
                setState((state) => ({
                    ...state,
                    newDataHolders: newDataHoldersArray,
                }));
            } catch (error) {
                //console.log(error);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }, // eslint-disable-next-line
        [getDataHolders, history])

    //eslint-disable-next-line
    const getPreConsentData = useCallback(props.api.getPreConsentData, []);
    const handleSelection = (selection: DataHolder, updateExisting: boolean = false) => {
        setState((state) => ({
            ...state,
            selectedDataHolder: selection,
        }));

        (async () => {
            try {
                const dataHolderBrand: string = JSON.stringify(selection);

                if (!selection.is_cdr) {
                    sessionStorage.setItem('dataHolderBrand', dataHolderBrand);
                    navigate(`${props.basePath}/bank-feeds-request`);
                } else {
                    const loadedPreConsentData = await getPreConsentData(selection.id);

                    if (loadedPreConsentData.consent_exists && !updateExisting) {
                        return openPopup();
                    }

                    if (props.onSelectionChange) {
                        loadedPreConsentData.data_holder_brand_identifier = selection.id;

                        if (loadedPreConsentData.consent_exists === undefined) {
                            loadedPreConsentData.consent_exists = false;
                        }

                        props.onSelectionChange(selection, loadedPreConsentData);
                    }

                    //Store data_holder_brand_identifier locally
                    sessionStorage.setItem('dataHolderBrand', dataHolderBrand);
                    navigate(props.next, {
                        state: {
                            returnToDashboard: state.returnToDashboard
                        }
                    });
                }
            } catch (error) {
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })()
    }

    const applyFilter = (newFilter: string) => {
        if (newFilter.length >= 3) {
            setState({
                ...state,
                filter: newFilter,
                showAllBanks: true
            })
        } else {
            setState({
                ...state,
                filter: undefined,
                showAllBanks: false
            })
        }
    }

    const handleGoBack = () => {
        navigate(-1);
    };

    const theme = useTheme();
    const style = {
        searchIcon: {
            color: theme.palette.primary.contrastText,
            right: 15
        },
        customerDataRight: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        },
        subheading: {
            marginTop: "18px",
            marginBottom: "8px",
            fontWeight: 600
        }
    };

    const shouldDisplayBank = (dataHolder: DataHolder, showAll: boolean | undefined) => {
        if (showAll){
            return true;   
        }
        const dataHolderTypesToInclude = props.preConsentConfig?.dataHolderTypesToInclude;
        if (dataHolderTypesToInclude === 'cdr') {
            return dataHolder.is_cdr && dataHolder.is_top_bank;
        }
        if (dataHolderTypesToInclude === 'non-cdr') {
            return !dataHolder.is_cdr && dataHolder.is_top_bank;;
        }

        return dataHolder.is_top_bank;
        /*
        if (dataHolder.is_cdr) {
            return true;
        }

        return props.preConsentConfig?.includeNonCdrDataHoldersForSelection;
        */
    }

    const shouldDisplayCdrDisclaimer = () => {
        if (props.isTrustedAdviser) {
            return false;
        }

        if (props.preConsentConfig?.dataHolderTypesToInclude) {
            return ['all', 'cdr'].indexOf(props.preConsentConfig.dataHolderTypesToInclude) >= 0;
        }

        return true;
    }

    const displayBanks = () => {

        setState(state => ({
            ...state,
            showAllBanks: true
        }));
    }

    return <div className={"page-wrapper data-holder-selection"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                {(state.newDataHolders)
                    ?
                    <>
                        <PrimaryBlock>
                            {props.preConsentConfig?.inclPreConsentStage && (
                                <div>
                                    <Link color={"secondary"} className={"go-back-link go-back-data-holder-selection"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                                </div>
                            )}
                            {props.isTrustedAdviser &&
                                <Grid container spacing={3} mt={4} mb={3}>
                                    <Grid item xs={12}>
                                        <h2 className="mb-0">Connection Methods</h2>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <h5><b>Consumer Data Right</b></h5>
                                        <p>The Consumer Data Right (CDR) in Australia allows you to share your data with third parties, in a secure manner while giving you more control over your data. For providers where the CDR logo is displayed, we will connect to your bank or institution using their CDR authentication method. You will not have to provide your account credentials, and you have direct control over the data you share.</p>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <h5><b>Digital Data Capture</b></h5>
                                        <p>For providers where a CDR logo is not displayed we will connect to your provider using digital data capture. You will be asked to provide your account credentials. For once off access we will immediately destroy your credentials after use. For ongoing access your credentials are secured with bank level 256-bit encryption and your data does not leave Australia.</p>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container spacing={3}>

                                <Grid item lg={shouldDisplayCdrDisclaimer() ? 9 : 12} xs={12} className={'institution-select-search'}>

                                    <h2>{props.preConsentConfig?.dataHolderSelectionHeading ?? "Choose your bank or institution"}</h2>

                                    {props.preConsentConfig?.dataHolderSelectionGuideHeading &&
                                        <Typography component={"h6"} sx={style.subheading}>{sanitize(props.preConsentConfig.dataHolderSelectionGuideHeading)}</Typography>
                                    }

                                    {props.preConsentConfig?.dataHolderSelectionGuideContent &&
                                        <Editor editorState={EditorState.createWithContent(convertFromRaw(
                                            JSON.parse(sanitize(props.preConsentConfig.dataHolderSelectionGuideContent))
                                        ))} readOnly={true} onChange={() => { }} />
                                    }

                                    <p className="mt-10">{props.isTrustedAdviser
                                        ? "Search for your bank or institution using the search bar below or select from the list."
                                        : "Search for your banking provider using the search bar below, or select from the list."}
                                    </p>

                                    {state.showNotice && (
                                        <Container maxWidth="lg" className="p-0">
                                            <div className="notice-box">
                                                <b>Notice</b>
                                                <p>
                                                    We could not complete the consent authorisation for the selected institution. Please re-select an institution to continue.
                                                </p>
                                            </div>
                                        </Container>
                                    )}

                                    <Paper className="search-box">
                                        <InputBase
                                            placeholder="Start typing your bank name"
                                            inputProps={{ "aria-label": "Start typing your bank name" }}
                                            onChange={(event) => applyFilter(event.target.value)}
                                            autoFocus
                                        />
                                        <Typography component={"span"}>
                                            <FontAwesomeIcon icon={faSearch} className={"v-center"} style={style.searchIcon} />
                                        </Typography>
                                    </Paper>

                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item lg={shouldDisplayCdrDisclaimer() ? 9 : 12} xs={12} className={"data-holder-search-result"}>
                                    <Grid container spacing={2}>
                                        {state.newDataHolders?.map((dataHolder: DataHolder, index: number) => {
                                            if (state.filter) {
                                                if (!dataHolder.title.toLowerCase().includes(state.filter.toLowerCase())) {
                                                    return <></>;
                                                }
                                            }
                                            const isAuthorised = props.haveAuthorisationWith && props.haveAuthorisationWith.has(dataHolder.id) && props.haveAuthorisationWith.get(dataHolder.id);
                                            const isCDR = dataHolder.is_cdr;
                                            const evenGrid = (index%2 === 1);
                                            return shouldDisplayBank(dataHolder, state.showAllBanks) ? <Grid item md={4} xs={6} key={index} className={`${index < 2000 ? "" : `institutions-overflow`}`}>

                                                <a href="#select" className={`data-holder ${isAuthorised ? 'authorised' : ""} ${isCDR ? 'cdr' : ""} ${evenGrid? "evenGrid" : ""}`} key={dataHolder.id} onClick={
                                                    (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        //isSelectedDataHolderAlreadyAddedToMultibankSession(dataHolder) ? openPopup() : handleSelection(dataHolder)
                                                        handleSelection(dataHolder);
                                                    }
                                                }>

                                                    <div className={'data-holder-logo'} style={{ backgroundImage: `url(${dataHolder.logo})` }} data-cdr={dataHolder.is_cdr}></div>
                                                    <p>{dataHolder.title}</p>
                                                    {isAuthorised && <FontAwesomeIcon icon={faCheckCircle} />}
                                                </a>
                                            </Grid> : ""
                                        })}
                                    </Grid>
                                </Grid>

                                {shouldDisplayCdrDisclaimer() && <Grid item lg={3} xs={12}>
                                    <div className={`consumer-data-right ${style.customerDataRight}`}>
                                        <h4>Consumer Data Right</h4>
                                        <p>Consumer Data Right has just been launched in Australia. Currently, there
                                            are a limited number of providers accredited under the system. Consumer
                                            Data Right will grow over time, as more and more providers become
                                            accredited.</p>
                                        <p><img src="/images/icon-cdr.png" width="32" alt={"data holder"}/>Those
                                            providers which have been accredited will also have a CDR logo
                                            displayed. Form more information, visit the <a
                                                href="https://www.cdr.gov.au/" target="_blank" rel="noreferrer">CDR
                                                website.</a></p>
                                    </div>
                                </Grid>}
                                
                                <Grid item xs={12} sm={8}>
                                    <ButtonBlock >
                                        <Button onClick={handleGoBack} variant={"outlined"} color={"secondary"}>Cancel</Button>
                                        {
                                            state.showAllBanks? "" :                           
                                            <Button className={'data-holder-showAll'} onClick={displayBanks} variant={"outlined"} color={"secondary"}>Show All</Button>
                                        }
                                    </ButtonBlock>
                                </Grid>

                            </Grid>

                        </PrimaryBlock>

                        {/* Information popup */}
                        <Popup
                            heading={"This bank has already been selected"}
                            open={open}
                            onClose={closePopup}
                            buttons={
                                <ButtonBlock>
                                    <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>Back</Button>
                                    <Button onClick={() => state.selectedDataHolder ? handleSelection(state.selectedDataHolder, true) : closePopup()} variant={"contained"} color={"secondary"}>Continue</Button>
                                </ButtonBlock>
                            }
                        >
                            You have already provided consent to this institution, would you like to update your consent?
                            {/*You have already added {state.selectedDataHolder?.title} to the list of connected institutions. Please select another bank.*/}
                        </Popup>
                    </>
                    : <LoadingSpinner position={"fixed"} overlay />
                }
            </main>
        </div>

        {!props.generalSettingsConfig?.hideFooter && <Footer generalSettingsConfig={props.generalSettingsConfig} />}

    </div>
}

export default DataHolderSelection;
