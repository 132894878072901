import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Grid, Link } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HighlightBlock from "../components/HighlightBlock";
import PrimaryBlock from "../components/PrimaryBlock";
import SwitchWithLabel from "../components/SwitchWithLabel";
import Consent from "../models/Consent";
import GeneralSettings from "../models/GeneralSettings";
import ModalPopUpData from "../models/ModalPopUpData";
import PreConsentData from "../models/PreConsentData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import CancellationPopup from "../popups/CancellationPopup";
import modalPopUpContents from "../popups/ModalPopUpDataHelper";
import Popup from "../popups/Popup";
import Insight from "../models/Insight";
import {sanitize} from "../helpers/HtmlSanitizerHelper";
import PreConsent from "../models/PreConsent";

interface previousDataDeleteConfirmationProps {
    onCancel?: () => void,
    next: string,
    prev: string,
    onDeleteOptionChange: (newOption: boolean) => void,
    currentDeleteOption: boolean | undefined
    loadedPreConsentData?: PreConsentData;
    consentConfig?: Consent;
    generalSettingsConfig?: GeneralSettings;
    preconsentConfig?: PreConsent;
    onIUnderstandButtonClick: (newPreConsentData: PreConsentData) => void;
    api: OpenBankingPlatformAPI;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    insightConfig?: Insight;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

const PreviousDataDeleteConfirmation = (props: previousDataDeleteConfirmationProps) => {
    const incInsightStage = props.insightConfig && props.generalSettingsConfig?.incInsightStage;
    const loadedPreConsentData: any = props.loadedPreConsentData;
    const [preConsentDataOptions, setPreConsentDataOptions] = useState<PreConsentData>(loadedPreConsentData);
    //var newDeleteOption = preConsentDataOptions?.delete_my_data_after_used === 1 ? true : false;
    var newDeleteOption = false;
    /*
    if (props.currentDeleteOption) {
        newDeleteOption = props.currentDeleteOption;
    }*/

    const [open, setOpen] = React.useState(false);
    const [opendeidentifypopup, setOpenGeneralPopUp] = React.useState(false);
    const [currentPopupModalData, setCurrentPopupModalData] = React.useState<ModalPopUpData>();
    const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);
    const [disableContinueButton, setDisableContinueButton] = React.useState(false);
    const navigate = useNavigate();

    const modalPopupsData = modalPopUpContents(
        props.generalSettingsConfig,
        props.preconsentConfig,
        props.consentConfig,
        props.generalSettingsConfig?.principalName,
        props.loadedPreConsentData?.data_holder_brand_name
    );

    const openPopup = () => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openGeneralPopup = (modalPopUpData: ModalPopUpData) => {
        setOpenGeneralPopUp(true);
        setCurrentPopupModalData(modalPopUpData);
    };

    const closeDeidentifyPopup = () => {
        setOpenGeneralPopUp(false);
    };

    const cancelProcess = () => {
        props.onCancel && props.onCancel();
    };

    const deIdentifyPopup = () => {
        const identifyPopUpDatas = modalPopupsData[1];
        openGeneralPopup(identifyPopUpDatas);
    }

    const deletePopup = () => {
        const deletePopUpDatas = modalPopupsData[2];
        openGeneralPopup(deletePopUpDatas);
    }

    const popUpgeneralTexts = currentPopupModalData?.popUpGeneralText.map((item, index) =>
        <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(item.content) }}></p>
    );

    const popUpBoxTexts = currentPopupModalData?.popupBoxContents.map((item) =>
        <>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </>
    );

    const popUpBlockTexts = currentPopupModalData?.popupBlockContents.map((item, index) => {
        return (
            <div key={index}>
                <h6>{item.heading}</h6>
                <br />
                {
                    item.content?.map((word, index) => {
                        return (
                            <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(word.text) }}></p>
                        )
                    })
                }
                <br />
            </div>
        )
    });

    const handleChange = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        /*
        if (props.onDeleteOptionChange) {
            props.onDeleteOptionChange(currentOption);
        }*/

        setPreConsentDataOptions({
            ...preConsentDataOptions,
            delete_previous_data_on_amend_consent: currentOption === true,
        })
        console.log(preConsentDataOptions);
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    // eslint-disable-next-line
    const savePreConsentData = useCallback(props.api.savePreConsentData, []);
    const handleIUnderstandClick = () => {
        setDisableContinueButton(true);
        props.onIUnderstandButtonClick(preConsentDataOptions);
        //Save and send to server
        (async () => {
            try {
                await savePreConsentData(preConsentDataOptions);
            } catch (error) {
                console.log(error);
            }
        })();

        navigate(props.next);
    }


    return <div className={"page-wrapper delete-confirmation"}>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth={"sm"} className={"p-0"}>
                        <Grid item xs={12} pb={3}>
                            <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                        </Grid>
                        <h3>We need to stop collecting and using your previously shared data before you continue.</h3>

                        <br/>
                            {(props.consentConfig?.consentAmendmentDataDeidentificationAndDeletionHeading) ?
                                <h6>{props.consentConfig?.consentAmendmentDataDeidentificationAndDeletionHeading}</h6> :
                                <h6>How would you like us to handle your previously shared data?</h6> }
                        <br/>

                        {(props.consentConfig?.includeDataDeidentificationStandardText) ?
                            <p>
                                {(props.consentConfig?.consentAmendmentDataDeidentificationText) ?
                                    <p>{props.consentConfig?.consentAmendmentDataDeidentificationText}</p> :
                                    <p>We will de-identify your data when we no longer need it to help you track your spending, and
                                        there Is no legal obligation to retain it.</p>
                                }

                                {(props.consentConfig.dataDeidentificationPopupLinkText && props.consentConfig?.dataDeidentificationPopupLinkText !== '') ?
                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                        e.preventDefault();
                                        deIdentifyPopup();
                                    }}>{props.consentConfig?.dataDeidentificationPopupLinkText}</a></p> :
                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                        e.preventDefault();
                                        deIdentifyPopup();
                                    }}>How and why we de-identify your data</a></p>
                                }
                            </p> : ""
                        }


                        {(incInsightStage ? props.insightConfig?.includeDataDeletionStandardText : props.consentConfig?.includeDataDeletionStandardText) ? (
                            <>
                                <HighlightBlock className="additional-use-choices">
                                    <div className={"item"}>
                                        <label>
                                            <SwitchWithLabel
                                                checked={deleteDataOption}
                                                onChange={value => { handleChange(value) }}
                                            >
                                                <h6><strong>Delete my data instead</strong></h6>
                                            </SwitchWithLabel>
                                        </label>
                                    </div>

                                    {(props.consentConfig?.dataDeletionPopupLinkText && props.consentConfig?.dataDeletionPopupLinkText !== '') ?

                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            deletePopup()
                                        }}>{props.consentConfig?.dataDeletionPopupLinkText}</a></p> :

                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            deletePopup()
                                        }}>See how we delete your data</a></p>
                                    }
                                </HighlightBlock>
                        </>) : ("")}

                        <ButtonBlock>
                            <Button onClick={openPopup} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button onClick={handleIUnderstandClick} disabled={disableContinueButton} variant={"contained"} color={"secondary"}>I understand</Button>
                        </ButtonBlock>
                        <Box className={"text-center"} mt={7}>
                            <img src="/images/hero-image-3.png" alt="" />
                        </Box>
                    </Container>
                </PrimaryBlock>

                {/* Cancellation popup */}
                <CancellationPopup
                    heading={"Cancel process?"}
                    open={open}
                    onYes={cancelProcess}
                    onNo={closePopup}
                >
                    <p>By not sharing your bank data, we will not be able to automatically collect this information. However, you can still provide this information manually when setting up {props.generalSettingsConfig?.principalName}.</p>
                    <p>Are you sure you would like to cancel this process?</p>
                </CancellationPopup>

                <Popup
                    heading={currentPopupModalData?.popUpHeading}
                    open={opendeidentifypopup}
                    withCloseButton
                    onClose={closeDeidentifyPopup}
                >
                    {popUpgeneralTexts}
                    {popUpBoxTexts && popUpBoxTexts.length > 0 &&
                        <Box my={3} p={3} className={"background-grey"}>{popUpBoxTexts}</Box>
                    }
                    {popUpBlockTexts}
                    <a href={props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer">View Consumer Data Right policy</a>
                </Popup>
            </main>
        </div>

        {!props.generalSettingsConfig?.hideFooter && <Footer generalSettingsConfig={props.generalSettingsConfig} />}

    </div>;
}

export default PreviousDataDeleteConfirmation;
