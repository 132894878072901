import React, { useCallback, useEffect, useState } from "react";

import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import {Box, Grid} from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import { LightMode } from "../../helpers/ThemeHelper";
import CurrentUser from "../../models/CurrentUser";
import GeneralSettings from "../../models/GeneralSettings";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Configuration from "../models/Configuration";
import FileItem from "./FileItem";
import LightTooltip from "./LightTooltip";
import {sanitize} from "../../helpers/HtmlSanitizerHelper";
import {isAdmin, isAdminOrClientManager} from "../../helpers/AuthUserHelper";
import ImagePreviews from "../models/ImagePreviews";
import EditableRichText from "./EditableRichText";


interface ViewGeneralSettingsProps
{
    adminObApi: OpenBankingPlatformAPIInterface;
    configuration: Configuration,
    generalSettings: GeneralSettings,
    currentUser?: CurrentUser,
    mtlsCertOptions?: Array<any>,
    imagePreviews?: ImagePreviews
}

const ViewGeneralSettings = (props: ViewGeneralSettingsProps) => {
    const logoUrl = `/principal-logos/${props.configuration.principal_id}/${props.generalSettings.cdrUploadLogo}`;
    const iconUrl = `/accreditation-icons/${props.configuration.principal_id}/${props.generalSettings.accreditationUploadIcon}`;
    const headerBgUrl = `/header-backgrounds/${props.configuration.principal_id}/${props.generalSettings.headerBackgroundImage}`;
    const pdfUrl = `/cdr-policy/${props.configuration.principal_id}/${props.generalSettings.cdrPolicyPDF}`;
    const [logoSrc, setLogoSrc] = useState('');
    const [iconSrc, setIconSrc] = useState('');
    const [headerBgSrc, setHeaderBgSrc] = useState('');
    const [PdfData, setPdfData]: any = useState();


    //eslint-disable-next-line
    const getFile = useCallback(props.adminObApi.getFile, []);
    useEffect(() => {
        (async (logoUrl, headerBgUrl, iconUrl) => {
            if (!props.imagePreviews?.cdrFileUploadLogoPreviewURL && props.generalSettings.cdrUploadLogo !== '') {
                try {
                    const data = await getFile(logoUrl);
                    setLogoSrc(URL.createObjectURL(data));
                } catch (error) {
                    console.log(error);
                }
            }

            if (!props.imagePreviews?.uploadHeaderBgPreviewURL && props.generalSettings.headerBackgroundImage !== '') {
                try {
                    const data = await getFile(headerBgUrl);
                    setHeaderBgSrc(URL.createObjectURL(data));
                } catch (error) {
                    console.log(error);
                }
            }

            if (!props.imagePreviews?.accreditationUploadIconPreviewURL && props.generalSettings.accreditationUploadIcon !== '') {
                try {
                    const data = await getFile(iconUrl);
                    setIconSrc(URL.createObjectURL(data));
                } catch (error) {
                    console.log(error);
                }
            }

        })(logoUrl, headerBgUrl, iconUrl);
        //eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (PdfData) {
            let blob = new Blob([PdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'cdr-policy.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [PdfData]);

    const handleShowCdrPolicyPDF = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        (async(logoUrl) => {
            try {
                const data = await getFile(logoUrl);
                setLogoSrc(URL.createObjectURL(data));
            } catch (error) {
                console.log(error);
            }
        })(logoUrl);

        (async(pdfUrl) => {
            try {
                const data = await getFile(pdfUrl);
                setPdfData(data);
            } catch (error) {
                console.log(error);
            }
        })(pdfUrl);
    };

    const ShowColor = ({ color }: { color: string }) => (
        <Grid item xs={12} sm={6}>
          {color !== "" ? (
            <div style={{
              display: 'flex',  
              alignItems: 'center', 
              justifyContent: 'left',
            }}>
              <div style={{
                width: '25px',
                height: '25px',
                backgroundColor: color,
                border: '3px solid #ccc',
                marginRight: '10px',
              }}></div>
              <span>{color}</span>
            </div>
          ) : null}
        </Grid>
      );
      
      const ShowFontFamily = ({fontFamily}:{fontFamily?: string}) => (
        <Grid item xs={12} sm={6}>
            <span style={{fontFamily: fontFamily}}>{fontFamily}</span>
        </Grid>
      )
      
      

    return <>
        {props.generalSettings ?
            <>
                <div className={"mt-50"}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <h4>Principal’s Details</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} md={7} className={"align-self-center"}>
                                    <label>Name<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Principal_name.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    {sanitize(props.generalSettings.principalName)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} md={7} className={"align-self-center"}>
                                    <label>ADR Name<LightTooltip
                                        title={"ADR Name"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    {sanitize(props.generalSettings.adrName)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} md={7} className={"align-self-center"}>
                                    <label>Reference Code<LightTooltip
                                        title={"Principal’s Reference Code"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    {sanitize(props.generalSettings.referenceCode)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} md={7} className={"align-self-center"}>
                                    <label>Is Active?<LightTooltip
                                        title={"Principal’s Reference Code"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6} md={5}>
                                    {props.generalSettings.isActive ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <h4>Legal Details</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>CDR Policy URL<LightTooltip
                                        title="Consumer data right Policy URL"
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.cdrPolicyURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>CDR Policy PDF<LightTooltip title="Consumer data right Policy PDF"
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={"file-item-wrap"}>
                                        <a href={"/#"} onClick={handleShowCdrPolicyPDF}>Download PDF</a>&nbsp;
                                        <a href={"/#"} onClick={handleShowCdrPolicyPDF}><FontAwesomeIcon icon={faDownload} /></a>
                                        {props.generalSettings.cdrPolicyPDFUploadDate && <div className={"date"} style={{paddingLeft:0}}>Uploaded {props.generalSettings.cdrPolicyPDFUploadDate}</div>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Box mb={5}><h4>General Styling</h4></Box>

                    <Box mb={2}><h6>Branding</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Brandmark / Brand Icon<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Brandmark_brand_icon.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div className={"file-item-wrap"}>
                                        <Box className={"details-box"}>
                                            <FileItem type={"image"} name={props.generalSettings.cdrUploadLogo} url={props.imagePreviews?.cdrFileUploadLogoPreviewURL || logoSrc}/>
                                        </Box>
                                        {props.generalSettings.cdrUploadLogoDate && <div className={"date"}>Uploaded {props.generalSettings.cdrUploadLogoDate}</div>}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Box mt={5}><h6>Accreditation Badge</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Accreditation number<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Accredation_number.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.accreditationNumber)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Show icon in accreditation badge<LightTooltip
                                            title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                            placement="right-start" arrow><InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.showIconInAccreditationBadge ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                            {props.generalSettings.showIconInAccreditationBadge &&
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>Icon for accreditation badge<LightTooltip
                                            title={<img className="tooltip-image" src="/images/admin/general-settings/Icon_for_accreditation_badge.png" alt="" />}
                                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={5} className={"grid-file-input"}>
                                        <div className={"file-item-wrap"}>
                                            <Box className={"details-box"}>
                                                <FileItem type={"image"} name={props.generalSettings.accreditationUploadIcon} url={props.imagePreviews?.accreditationUploadIconPreviewURL || iconSrc}/>
                                            </Box>
                                            {props.generalSettings.accreditationUploadIconDate && <div className={"date"}>Uploaded {props.generalSettings.accreditationUploadIconDate}</div>}
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    

                    <Box mt={5} mb={2}><h6>Header</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Header background type<LightTooltip
                                        title={"Header background type"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.headerBackgroundType || "No background"}
                                </Grid>
                            </Grid>
                            {props.generalSettings.headerBackgroundType === "Solid colour" ?
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>Header background colour<LightTooltip
                                            title={<img className="tooltip-image" src="/images/admin/general-settings/Header_background_colour.png" alt="" />}
                                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                    </Grid>
                                    <ShowColor color={props.generalSettings.headerBackgroundColor || ""}/>
                                </Grid>
                                : ""
                            }
                            {props.generalSettings.headerBackgroundType === "Image background" ?
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>Header background colour<LightTooltip
                                            title={<img className="tooltip-image" src="/images/admin/general-settings/Header_background_image.png" alt="" />}
                                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className={"grid-file-input"}>
                                        <div className={"file-item-wrap"}>
                                            <Box className={"details-box"}>
                                                <FileItem type={"image"} name={props.generalSettings.headerBackgroundImage} url={props.imagePreviews?.uploadHeaderBgPreviewURL || headerBgSrc}/>
                                            </Box>
                                            {props.generalSettings.headerBackgroundImageUploadDate && <div className={"date"}>Uploaded {props.generalSettings.headerBackgroundImageUploadDate}</div>}
                                        </div>
                                    </Grid>
                                </Grid>
                                : ""
                            }
                        </Grid>
                    </Grid>

                    <Box mt={3} mb={3}><h6>Footer</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Footer background type<LightTooltip
                                        title={"Header background type"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.footerBackgroundType || "No background"}
                                </Grid>
                            </Grid>
                            {props.generalSettings.footerBackgroundType === "Solid colour" &&
                                <>
                                    <Grid container className={"form-group"}>
                                        <Grid item xs={12} sm={6} className={"align-self-center"}>
                                            <label>Footer background colour<LightTooltip
                                                title={<img className="tooltip-image" src="/images/admin/general-settings/Footer_background_colour.png" alt="" />}
                                                placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                        </Grid>
                                        <ShowColor color={props.generalSettings.footerBackgroundColor || ""}/>
                                    </Grid>
                                    <Grid container className={"form-group"}>
                                        <Grid item xs={12} sm={6} className={"align-self-center"}>
                                            <label>Footer text colour<LightTooltip
                                                title={<img className="tooltip-image" src="/images/admin/general-settings/Footer_text_colour.png" alt="" />}
                                                placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                        </Grid>
                                        <ShowColor color={props.generalSettings.footerTextColor || ""}/>
                                    </Grid>
                                </>
                            }
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Footer Text</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <EditableRichText
                                        editMode={false}
                                        value={props.generalSettings.footerText}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Footer Text Alignment</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.footerTextAlignment}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Hide footer<LightTooltip
                                        title={"Hide footer"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.hideFooter ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    

                    <Box mt={3} mb={3}><h6>Typefaces</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Font family for headings<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_headings.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowFontFamily fontFamily={props.generalSettings.headingFontFamily || "Nunito Sans"} />
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Font family for body text<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_body_text.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowFontFamily fontFamily={props.generalSettings.bodyTextFontFamily || "Nunito Sans"}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Font family for footer text<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Font_family_for_footers.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowFontFamily fontFamily={props.generalSettings.footerFontFamily || "Nunito Sans"}/>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Box mt={3} mb={3}><h6>Colour system</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Theme type<LightTooltip
                                        title={"Theme type"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.themeType}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Background colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Background_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.backgroundColor || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Body text colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Body_text_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.textColor || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Primary colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Primary_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.primaryColor ?? LightMode.primaryColor}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Text colour for Primary Background<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_primary_background.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.primaryBGTextColor ?? LightMode.primaryBGTextColor}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Secondary colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Secondary_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.secondaryColor ?? LightMode.secondaryColor}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Text colour for Secondary Background<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_secondary_background.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.secondaryBGTextColor ?? LightMode.secondaryBGTextColor}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Tertiary colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Tertiary_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.tertiaryColor || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Text colour for Tertiary Background<LightTooltip
                                        title={"Text colour for Tertiary Background"}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.tertiaryBGTextColor ?? LightMode.tertiaryBGTextColor}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Success colour<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Success_colour.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.successColor || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Text colour for Success Background<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Text_colour_for_success_background.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.successBGTextColor ?? LightMode.successBGTextColor}/>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Box mt={5} mb={3}><h6>Elements / Components</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Primary button text style<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Primary_button_text_style.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.primaryButtonTextStyle?.toUpperCase() || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Inverse button style<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_style.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <ShowColor color={props.generalSettings.inverseButtonTextStyle?.toUpperCase() || ""}/>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Inverse button text style<LightTooltip
                                        title={<img className="tooltip-image" src="/images/admin/general-settings/Inverse_button_text_style.png" alt="" />}
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.inverseButtonStyle?.toUpperCase().replace("transparent", "Transparent")}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Button hover effect<LightTooltip
                                        title="Button hover effect"
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.buttonHoverEffect}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Link hover effect<LightTooltip
                                        title="Button hover effect"
                                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.linkHoverEffect}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                
                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <h4>Insight Settings</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Insight Stage</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.incInsightStage ? "Include" : "Exclude"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <h4>API Settings</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Provide Consent Via API<LightTooltip title="Provide Consent Via API" placement="right-start"
                                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.provideConsentViaApi ? 'Yes' : 'No'}
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Enable Multibank Sessions<LightTooltip title="Enable Multibank Sessions" placement="right-start"
                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.multibankEnabled ? 'Yes' : 'No'}
                                </Grid>
                            </Grid>

                            {isAdminOrClientManager(props.currentUser?.role_id) ?
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>API Key<LightTooltip title="Principal’s API Key" placement="right-start"
                                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {props.generalSettings.principalUuid}
                                    </Grid>
                                </Grid> : ""
                            }

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Statement Delivery Email
                                       <LightTooltip title="Please specify one or more email addresses each separated by semi-colon as a delimiter, e.g. test1@test.com.au; test2@test.com.au; ...." placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.statementDeliveryEmail)}
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Schema Version<LightTooltip title="Schema Version" placement="right-start"
                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.schemaVersion ? props.generalSettings.schemaVersion : ''}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Raw Data Export Format<LightTooltip title="Raw Data Export Format" placement="right-start"
                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.dataExportFormat ? props.generalSettings.dataExportFormat.toUpperCase() : ''}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Statement Output Format<LightTooltip title="Statement Output Format" placement="right-start"
                                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.statementExportFormat?props.generalSettings.statementExportFormat:''}
                                </Grid>
                            </Grid>

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Statement Transaction Period (Days)<LightTooltip title="Statement Transaction Period (Days)"
                                                                        placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.statementTransactionPeriodDays}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Statement Delivery Endpoint
                                       <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.statementDeliveryEndpoint)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Deliver E-Statement<LightTooltip title="Deliver E-Statement" placement="right-start"
                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.isDeliverEstatement ? 'Yes' : 'No'}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Consent Expiry Notification Endpoint
                                       <LightTooltip title="Please specify one or more URLs each separated by semi-colon as a delimiter, e.g. https://test1.com.au; https://test2.com.au; ...." placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.consentExpiryNotificationEndpoint}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>JS Post Message Domain URL
                                       <LightTooltip title="This specifies the domain for a JS Post Message - it should be set to the domain (e.g. http://yourdomain.com) of your application from which the JS Post message data from the Consent Flow will be sent to the parent frame"
                                                    placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.callbackURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Reporting Delivery Email
                                       <LightTooltip title="Please specify Reporting Delivery Email" placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.reportingDeliveryEmail)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box mt={5} mb={3}><h6>Consent Email Notifications</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={6}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Send Consent Expiry Email?<LightTooltip title="Send Consent Expiry Email?"
                                                                                  placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.sendConsentExpiryEmail ? 'Yes' : 'No'}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Consent Expiry Email Text<LightTooltip title="Consent Expiry Email Text"
                                                                                  placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.consentExpiryEmailText)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Box mt={5} mb={3}><h6>Consent API Webhook Notifications</h6></Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Consent Confirmation URL<LightTooltip title="Consent Confirmation URL"
                                                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.consentConfirmationPdfURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Consent Withdrawal URL<LightTooltip title="Consent Withdrawal URL"
                                                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.consentRevocationPdfURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Recurring Consent Reminder URL<LightTooltip title="Recurring Consent Reminder URL"
                                                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.consentRecurringNotificationURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Consent Expiry URL<LightTooltip title="Consent Expiry URL"
                                                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.consentExpiryPdfURL)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                {isAdmin(props.currentUser?.role_id) ?
                    <>
                        <Box mt={5} mb={3}><h6>MTLS</h6></Box>
                        <Grid container spacing={10}>
                            <Grid item xs={12} md={6}>
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={6} className={"align-self-center"}>
                                        <label>MTLS Cert/Key Configuration:<LightTooltip title="MTLS Certificate Set"
                                                                                                  placement="right-start" arrow><InfoIcon
                                            color={"secondary"} /></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {props.mtlsCertOptions && props.mtlsCertOptions.filter(item => {
                                            return item.identifier === props.generalSettings.mtlsCertificatesId
                                        }).map((item, i) =>
                                            <span>{item.name}</span>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    : ""}

                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <h4>Redirection Settings</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Success Redirect URL
                                       <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the successful completion of the Consent Flow. It provided it will automatically triger to redirect to the specifid location after 5 seconds"
                                                    placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.redirectURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Redirect After Seconds
                                        <LightTooltip title="Automatically triger to redirect to the specifid location after setup number of seconds"
                                                      placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.redirectAfterSeconds}
                                </Grid>
                            </Grid>
                            <Box mt={5} mb={3}><h6>Cancellation Settings</h6></Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Show Cancel Button
                                        <LightTooltip
                                            title={<img className="tooltip-image" src="/images/admin/pre-consent/Cancel_button_cdr.png" alt="" />}
                                            placement="right-start" arrow><InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.includeCancelButtonAndPopUp ? "Include" : "Exclude"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Cancellation Redirect URL
                                        <LightTooltip title="This specifies a URL for the Consent Flow to redirect back to after the user cancels the Consent Flow. If provided, the redirect will be triggered to this URL after the user confirms cancellation."
                                                    placement="right-start" arrow>
                                            <InfoIcon color={"secondary"}/>
                                        </LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.cancellationRedirectURL)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Cancellation Modal Heading
                                        <LightTooltip title="Cancel modal heading" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.cancelModalPopUpHeading)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Cancellation Modal Text
                                        <LightTooltip title="Cancel modal text" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip>
                                    </label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.cancelModalPopUpMainText)}
                                </Grid>
                            </Grid>
                            <Box mt={5} mb={3}><h6>Enhanced Redirection Flow</h6></Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Enable for success</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.enhancedRedirection?.enableForSuccess ? "Active" : "Not active"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Enable for cancellation</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.enhancedRedirection?.enableForCancellation ? "Active" : "Not active"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Client ID</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.enhancedRedirection?.clientId)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Client Secret</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.enhancedRedirection?.clientSecret)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Client credentials API endpoint URL</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.enhancedRedirection?.clientCredentialsEndpoint)}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>JWT generation API endpoint URL</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {sanitize(props.generalSettings.enhancedRedirection?.jwtGenerationEndpoint)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <h4>Additional Settings</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Banks Overridden <br />For CDR Data Retrieval<LightTooltip title="Override CDR data retrieval"
                                                                    placement="right-start" arrow><InfoIcon
                                        color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ul>
                                        {props.generalSettings.cdrRetrievalOverridedDataHolders?.map(el => <li>{el.name}</li>)}
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                {isAdmin(props.currentUser?.role_id) ?
                    <>
                        <hr className={"mt-30"}/>

                        <div className={"mt-50"}>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={7}>
                                    <Box mb={2}>
                                        <h4>Direct Data Capture Settings</h4>
                                    </Box>

                                    <Grid container className={"form-group"}>
                                        <Grid item xs={12} sm={6} className={"align-self-center"}>
                                            <label>BankFeeds API key</label>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {sanitize(props.generalSettings.directDataCaptureAPIKey)}
                                        </Grid>
                                    </Grid>

                                    <Grid container className={"form-group"}>
                                        <Grid item xs={12} sm={6} className={"align-self-center"}>
                                            <label>Once-Off Data Retrieval Only?</label>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {props.generalSettings.directDataCaptureOnceOffRetrieval ? "Yes" : "No"}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </>: ""
                }

                <hr className={"mt-30"}/>

                <div className={"mt-50"}>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7}>
                            <Box mb={2}>
                                <h4>External Data Pipelines</h4>
                            </Box>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Enhanced Categorisation</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.enhancedCategorisation ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>iTRS Score</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.itrsScore ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Categorisation Improvement</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.categorisationImprovement ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={6} className={"align-self-center"}>
                                    <label>Spend Analytics</label>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {props.generalSettings.spendAnalytics ? "Yes" : "No"}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </>
            : <LoadingSpinner position={"fixed"} overlay />
        }
    </>;
}

export default ViewGeneralSettings;