import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Container } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import LoadingSpinner from "../components/LoadingSpinner";
import MultiBankSelection from "../components/MultiBankSelection";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import MultibankSelectionData from "../models/MultibankSelectionData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface MultiBankSelectionListProps {
    basePath: string;
    api: OpenBankingPlatformAPI;
    generalSettingsConfig?: GeneralSettings;
    currentDataHolderSelection?: DataHolder
    connectAnotherDataHolderPath: string;
    onRedirectBack: (storedDataHolderBrand: DataHolder) => void;
    hasApiError?: boolean;
    next: string;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface MultibankSessionState {
    data: Array<MultibankSelectionData>;
    consentId?: string;
    consentIsCdr?: boolean;
    showNotice?: boolean;
}

const MultiBankSelectionList = (props: MultiBankSelectionListProps) => {
    const [disableContinueButton, setDisableContinueButton] = React.useState(false);
    const [loadScreen, setLoadScreen] = React.useState(true);
    const [loadSession, setUpdateSession] = useState<MultibankSessionState>({
        data: [],
    });

    //Extract url parameters
    const params = new URLSearchParams(window.location.hash.replace("#", "?"));
    // Common parameters
    const state = params.get('state') ?? '';
    // Success parameters
    const code = params.get('code') ?? '';
    const id_token = params.get('id_token') ?? '';
    const access_token = params.get('access_token') ?? '';
    // Error parameters
    const error = params.get('error') ?? '';
    const error_description = params.get('error_description') ?? '';
    const error_uri = params.get('error_uri') ?? '';

    //Data holder selection storage
    let dataHolderBrand = sessionStorage.getItem('dataHolderBrand') ?? JSON.stringify(props.currentDataHolderSelection);
    if(dataHolderBrand){
        sessionStorage.setItem('previousDataHolderBrand',dataHolderBrand);
    }else{
        // ODS 776
        // after finished one consent, user could click add new bank and then go to the select a new bank page
        // there is a back button on this page, if clicked before a new bank is selected, will go back to this method
        // since no new bank is selected, so dataHolderBrand is empty, thus resulting in undefined error
        // so added previousDataHolderBrand to session log just to remember previously selected bank
        dataHolderBrand = sessionStorage.getItem('previousDataHolderBrand') ?? JSON.stringify(props.currentDataHolderSelection);
    }
    const storedDataHolderBrand: DataHolder = JSON.parse(dataHolderBrand);
    const postConsentData = {
        'code': code,
        'id_token': id_token,
        'state': state,
        'access_token': access_token,
        'error': error,
        'error_description': error_description,
        'error_uri': error_uri,
        'data_holder_brand_identifier': storedDataHolderBrand.id,
        'isMultibankSession': true
    };

    const navigate = useNavigate();

    const location = useLocation();
    /*
    useEffect(() => {
        if (location.state) {
            const consentID = location.state as number;
            setUpdateSession((state)=>({
                ...state,
                consentId: consentID
            }))
        }
    }, [location]);
    */

    var sessionId = sessionStorage.getItem('sessionId');

    //eslint-disable-next-line
    const savePostConsentToken = useCallback(props.api.savePostConsentToken, []);
    //eslint-disable-next-line
    const getSessionDataHolders = useCallback(props.api.getMultibankSession, []);
    useEffect(() => {
        /**
         * TODO: validate the value of the state parameter against the one generated and stored
         * for the initial auth request before we allow the rest of the processing to proceed
         */
        if (location.state) {
            const locationState = location.state as any;

            setUpdateSession((state) => ({
                ...state,
                consentId: locationState.consentId || locationState,
            }))
        } else {
            // Update the document title using the browser API
            props.onRedirectBack(storedDataHolderBrand);
            (async () => {
                if (storedDataHolderBrand.is_cdr) {
                    try {
                        let existingConsentData = sessionStorage.getItem('lastConsentData');
                        //console.log("abd0");
                        //console.log(existingConsentData);
                        //console.log(JSON.stringify(postConsentData));
                        if(existingConsentData !== (JSON.stringify(postConsentData))){
                            //console.log("abd");
                            sessionStorage.setItem('lastConsentData', JSON.stringify(postConsentData));
                            //Save all tokens
                            const consentData = await savePostConsentToken(postConsentData);
                            //console.log("abd2");

                            setUpdateSession((state) => ({
                                ...state,
                                consentId: consentData.consent_uuid,
                                consentIsCdr: storedDataHolderBrand.is_cdr
                            }))
                            setLoadScreen(true);
                            navigate(props.basePath + `/consent/success`, {
                                state: {
                                    consentId: consentData.consent_uuid
                                }
                            })
                        }
                    } catch (error) {
                        try {
                            if ((error as any).response.data.source !== 'openid' || sessionId === null) {
                                return redirectToDataHolderListWithNotice();
                            }

                            const getSessionData = await getSessionDataHolders(sessionId);
                            setUpdateSession((state) => ({
                                ...state,
                                data: getSessionData.reverse(), // Reverse order of the added banks to put the most recent one on top
                                showNotice: true,
                            }));

                            if (getSessionData.length === 0) {
                                return redirectToDataHolderListWithNotice();
                            }

                            setLoadScreen(false);
                        } catch (error) {
                            consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                        }
                    }
                }
            })();
        }
    },
        // eslint-disable-next-line
        [location]
    );

    const redirectToDataHolderListWithNotice = () => {
        navigate(props.connectAnotherDataHolderPath, {
            state: {
                showNotice: true
            }
        });
    }

    const isNewlyAddedDataHolder = (data: MultibankSelectionData) => {
        return data.brand_identifier === storedDataHolderBrand.id;
    }

    //eslint-disable-next-line
    const updateSession = useCallback(props.api.saveMultibankSession, []);
    useEffect(() => {
        (async () => {
            try {
                if (loadSession.consentId) {
                    const newSessionData = await updateSession(storedDataHolderBrand.id, (sessionId !== null ? sessionId : ''), loadSession?.consentId);
                    if (newSessionData.multibank_session_id) {
                        sessionStorage.setItem('sessionId', newSessionData.multibank_session_id);
                    }
                    const getSessionData = await getSessionDataHolders(newSessionData.multibank_session_id);
                    setUpdateSession((state) => ({
                        ...state,
                        data: getSessionData.reverse(), // Reverse order of the added banks to put the most recent one on top
                    }))
                    setLoadScreen(false);
                    let hasDefaultDataRequestTypes = sessionStorage.getItem('hasDefaultDataRequestTypes');
                    if (!hasDefaultDataRequestTypes && loadSession.consentIsCdr) {
                        sessionStorage.setItem('hasDefaultDataRequestTypes', 'true');
                    }
                }
            } catch (error) {
                // TODO Uncomment this code when fallback is removed
                setUpdateSession((state) => ({
                    ...state,
                    apiHasReturnedAnError: true,
                }))
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    },
        //eslint-disable-next-line
        [loadSession.consentId]
    )

    // Scroll to the top of the page once
    useEffect(() => {
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, []);

    // eslint-disable-next-line
    const processMultibankSession = useCallback(props.api.processMultibankSession, []);
    const handleFinishAndSubmit = () => {
        setDisableContinueButton(true);
        (async () => {
            try {
                const multiBankSelectionData = await processMultibankSession((sessionId !== null ? sessionId : ''));
                //navigate(props.next);
                navigate(props.next, {
                    state: {
                        multiBankSelectionData: multiBankSelectionData
                    }
                });
            } catch (error) {
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    return <div className={"page-wrapper multi-bank-selection"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                {
                    (!loadScreen) ?
                        ((props.generalSettingsConfig) ? (
                            <>
                                <PrimaryBlock>
                                    <div className={"multibank-confirmation"}>
                                        <h2>Institutions you have connected to</h2>

                                        {loadSession.showNotice && (
                                            <Container maxWidth="sm" className="p-0">
                                                <div className="notice-box">
                                                    <b>Notice</b>
                                                    <p>
                                                        We could not complete the consent authorisation for the selected institution. You may try again or choose to complete your submission with the selected institutions.
                                                    </p>
                                                </div>
                                            </Container>
                                        )}

                                        {!loadSession.showNotice && <p>You have successfully shared data from your institution. Please see below for detailed information.</p>}

                                        <div className={"multibank-list mt-30"}>
                                            <Container maxWidth="sm" className="p-0">
                                                {loadSession.data && (
                                                    loadSession.data.map((item, i) =>
                                                        <MultiBankSelection basePath={props.basePath} key={i} index={i} isNewlyAddedDataHolder={isNewlyAddedDataHolder(loadSession.data[i])} api={props.api} {...item} />
                                                    ))}
                                            </Container>
                                        </div>
                                        <Box my={5}>
                                            <ButtonBlock className="alignItemsCenter">
                                                <LinkButton targetlocation={props.connectAnotherDataHolderPath} variant={"outlined"} color={"secondary"}>Add another bank</LinkButton>
                                                <Button onClick={handleFinishAndSubmit} disabled={disableContinueButton} variant={"contained"} color={"secondary"}>Finish</Button>
                                            </ButtonBlock>
                                        </Box>
                                    </div>
                                </PrimaryBlock>
                            </>
                        )
                            : (props.hasApiError)
                                ? <>
                                    <h3 className={"error-title"}>Error</h3>
                                    <p className={"error-text"}>We're sorry but our system has encountered an error. Please try
                                        again later or contact support.</p>
                                </>
                                : <LoadingSpinner position={"fixed"} overlay />) : <LoadingSpinner position={"fixed"} overlay />
                }
            </main>
        </div>

        {!props.generalSettingsConfig?.hideFooter && <Footer generalSettingsConfig={props.generalSettingsConfig} />}

    </div>;
}

export default MultiBankSelectionList;