import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import MultibankSelectionData from "../models/MultibankSelectionData";
import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";

interface MultibankSelectionProps extends MultibankSelectionData {
    basePath: string;
    api: OpenBankingPlatformAPIInterface;
    index: number;
    isNewlyAddedDataHolder: boolean;
}

interface MultiBankSelectionState
{
    downloadPdfData?: any;
}

const MultiBankSelection = (props: MultibankSelectionProps) => {
    const navigate = useNavigate();

    const [state, setState] = useState<MultiBankSelectionState>({
    });

    //eslint-disable-next-line
    const getDownloadPdfData = useCallback(props.api.getDownloadPdf, []);
    const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
        //Send to server
        (async (consent_uuid, consent_type) => {
            try {
                const pdfData = await getDownloadPdfData(consent_uuid, consent_type);
                setState(state => ({
                    ...state,
                    downloadPdfData: pdfData,
                }));
            } catch (error) {
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(consent_uuid, consent_type);
    }
    useEffect(() => {
        if (state.downloadPdfData) {
            let blob = new Blob([state.downloadPdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'consent.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [state.downloadPdfData]);

    const theme = useTheme();
    const styles = {
        confirmationMessage: {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText
        }
    };

    return <div className="multibank-selection text-align-left">
        <Grid container className="institution-container institution-card">
            <Grid className="institution-logo" item xs={2}>
                <img src={props.logo_uri} alt="" />
            </Grid>
            <Grid className="institution-name" item xs={9}>
                <p>{props.institution_name}</p>
            </Grid>
            <Grid className="cdr-logo text-right" item xs={1}>
                {(props.is_cdr) ?
                <img src={"/images/icon-cdr.png"} alt="" />
                : ""}
            </Grid>
        </Grid>

        {props.isNewlyAddedDataHolder &&
            <Box component="div" className={"added-confirmation mt-2"} sx={styles.confirmationMessage}>
                You have successfully consented to sharing your {props.institution_name} data with us.{props.is_cdr ? " We will email you a CDR Receipt, and a link to your Consumer Dashboard. You can also download your CDR Receipt below" : ""}.
            </Box>
        }

        {(props.is_cdr) ?
        <Typography className="confirmation-link mt-3" color={"secondary"}>
            <FontAwesomeIcon icon={faDownload} color={"secondary"} />&nbsp; <a id={"downloadPdf-" + props.index} href="/#" onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(props.consent_uuid, 1);}} >Download confirmation of consent</a>
        </Typography>
        : ""}
    </div>
}

export default MultiBankSelection;